import React from "react";
import { graphql } from "gatsby";

// Components
import { RichText, Asset } from "../../components";
import PhotoCards, { PhotoCardsProps } from "../../components/PhotoCard";

// Styles
import "./styles.css";

const Page = ({ data, location }: any) => {
  const { title, authorName, autherImage, photoCards, lastSection } =
    data.contentfulTaboolaPageV3;

  return (
    <main>
      <article className="container m-auto p-10 flex flex-col gap-3">
        {title && <h2 className="font-bold text-[16px]">{title}</h2>}
        {authorName && (
          <div className="flex items-center gap-2 mb-2">
            <Asset className="w-[30px]" data={autherImage} />
            {authorName}
          </div>
        )}
        {photoCards.map((card: PhotoCardsProps) => (
          <PhotoCards {...card} location={location} />
        ))}

        <div className="last-section bg-[#00000040] p-6">
          <RichText contentfullText={lastSection} />
        </div>

        <div className="text-[8px] opacity-50">
          Free-to-play social casino game, entertainment only, with in-app
          purchases. Not real money gambling. Success in-game does not imply
          success in real gambling. Must be 18 or older to play
        </div>
      </article>
    </main>
  );
};

export const Head = ({ data }: any) => {
  return (
    <>
      <title>{data.contentfulTaboolaPageV3.name}</title>
    </>
  );
};

export const data = graphql`
  query defaultPage($slug: String) {
    contentfulTaboolaPageV3(slug: { eq: $slug }) {
      name
      slug
      title
      authorName
      autherImage {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
        }
      }
      photoCards {
        title
        description {
          raw
        }
        image {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
        text
        photoBy
        link
      }
      lastSection {
        raw
      }
    }
  }
`;

export default Page;
